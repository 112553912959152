<template>
    <div>
        <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
            <b-row>
                <b-col md="6">
                    <b-card no-body border-variant="primary">
                        <b-row class="m-1">
                            <b-col md="6">
                                <b-form-group label="Conexión" label-for="idEmpresa" label-class="font-weight-bold">
                                    <v-select id="idEmpresa"                                        
                                        class="select-size-sm"
                                        v-model="filter.idEmpresa"
                                        :options="connections"
                                        :reduce="option => option.idEmpresa" 
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false"
                                        @input="selectConnection">
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Busqueda Por" label-for="search_id" label-class="font-weight-bold">
                                    <v-select id="search_id"
                                        :disabled="!filter.idEmpresa"
                                        class="select-size-sm"
                                        v-model="filter.category_key"
                                        :options="categories"
                                        :reduce="option => option.key" 
                                        label="label" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false"
                                        @input="selectSearchCategory">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col v-if="filter.category_key === 'prices'" cols="12" md="6">
                                <b-form-group label="Lista Precios" label-for="precio_Empresa_Id" label-class="font-weight-bold">
                                    <v-select id="precio_Empresa_Id"
                                        class="select-size-sm"
                                        v-model="filter.precioEmpresaId"
                                        :options="pricesList"
                                        :reduce="option => option.precio_Empresa_Id" 
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col v-if="filter.category_key === 'levels'" md="6">
                                <b-form-group label="Almacén" label-for="warehouse_id" label-class="font-weight-bold">
                                    <v-select id="warehouse_id"
                                        class="select-size-sm"
                                        v-model="filter.warehouse_id"
                                        :options="warehouses"
                                        :reduce="option => option.almacen_Id"
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col v-if="filter.category_key === 'particular_data'" md="6">
                                <b-form-group label="Datos Particulares" label-for="attribute_id" label-class="font-weight-bold">
                                    <v-select id="attribute_id"
                                        class="select-size-sm"
                                        v-model="filter.attribute_id"
                                        :options="attributes"
                                        :reduce="option => option.atributo_Id"
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false"
                                        @input="selectParticularData">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col v-if="filter.category_key === 'classificator'" md="6">
                                <b-form-group label="Clasificadores" label-for="classificator_id" label-class="font-weight-bold">
                                    <v-select id="classificator_id"
                                        class="select-size-sm"
                                        v-model="filter.classificator_id"
                                        :options="classificators"
                                        :reduce="option => option.clasificador_Id"
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false"
                                        @input="selectClassificator">
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <!-- <b-col v-if="filter.category_key === 'classificator' && filter.classificator_id" md="6">
                                <b-form-group label="Clasificación Valores" label-for="valor_clasif_id">
                                    <v-select class="select-size-sm" id="valor_clasif_id" v-model="filter.valor_clasif_id"
                                        :options="classificatorsValues" :reduce="option => option.valor_Clasif_Id" label="valor"
                                        placeholder="Selecciona una opción" :clearable="false" :searchable="true">
                                    </v-select>
                                </b-form-group>
                            </b-col> -->

                            <b-col md="2" class="mt-2">
                                <b-button :disabled="!filter.idEmpresa || !filter.category_key" size="sm" variant="primary" @click="getArticles">Buscar</b-button>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col v-if="filter.idEmpresa && filter.category_key && articles.length > 0" md="6">
                    <b-card no-body border-variant="warning">
                        <b-row class="m-1">
                            <b-col cols="12" md="6">
                                <b-form-group label="Conexión" label-for="idEmpresa2" label-class="font-weight-bold">
                                    <v-select id="idEmpresa2"                                        
                                        class="select-size-sm"
                                        v-model="filter2.idEmpresa"
                                        :options="connections.filter(item => item.idEmpresa !== filter.idEmpresa)"
                                        :reduce="option => option.idEmpresa" 
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false"
                                        @input="selectConnection2">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="6">
                                <b-form-group label="Busqueda Por" label-for="search_id2" label-class="font-weight-bold">
                                        <v-select id="search_id2"
                                            class="select-size-sm"
                                            v-model="filter2.category_key"
                                            :options="categories"
                                            :reduce="option => option.key" 
                                            label="label" placeholder="Selecciona una opción"
                                            :clearable="false" :searchable="false"
                                            @input="selectPriceList2">
                                        </v-select>
                                    </b-form-group>
                                <!-- <b-form-group label="Lista Precios" label-for="precio_Empresa_Id2" label-class="font-weight-bold">
                                    <v-select id="precio_Empresa_Id2"
                                        class="select-size-sm"
                                        v-model="filter2.precioEmpresaId"
                                        :options="priceList2"
                                        :reduce="option => option.precio_Empresa_Id" 
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false"
                                        @input="selectPriceList2">
                                    </v-select>
                                </b-form-group> -->
                            </b-col>

                            <b-col v-if="filter2.category_key === 'levels'" md="6">
                                <b-form-group label="Almacén" label-for="warehouse_id2" label-class="font-weight-bold">
                                    <v-select id="warehouse_id2"
                                        class="select-size-sm"
                                        v-model="filter2.warehouse_id"
                                        :options="warehouses2"
                                        :reduce="option => option.almacen_Id"
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col v-if="filter2.category_key === 'particular_data'" md="6">
                                <b-form-group label="Datos Particulares" label-for="attribute_id2" label-class="font-weight-bold">
                                    <v-select id="attribute_id2"
                                        class="select-size-sm"
                                        v-model="filter2.attribute_id"
                                        :options="attributes2"
                                        :reduce="option => option.atributo_Id"
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col v-if="filter2.category_key === 'classificator'" md="6">
                                <b-form-group label="Clasificadores" label-for="classificator_id2" label-class="font-weight-bold">
                                    <v-select id="classificator_id2"
                                        class="select-size-sm"
                                        v-model="filter2.classificator_id"
                                        :options="classificators2"
                                        :reduce="option => option.clasificador_Id"
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false"
                                        @input="selectClassificator2">
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <!-- <b-col v-if="filter2.category_key === 'classificator' && filter2.classificator_id" md="6">
                                <b-form-group label="Clasificación Valores" label-for="valor_clasif_id2">
                                    <v-select class="select-size-sm" id="valor_clasif_id2" v-model="filter2.valor_clasif_id"
                                        :options="classificatorsValues2" :reduce="option => option.valor_Clasif_Id" label="valor"
                                        placeholder="Selecciona una opción" :clearable="false" :searchable="false">
                                    </v-select>
                                </b-form-group>
                            </b-col> -->

                            <b-col v-if="filter2.category_key === 'prices'" cols="12" md="6">
                                <b-form-group label="Lista Precios" label-for="precio_Empresa_Id2" label-class="font-weight-bold">
                                    <v-select id="precio_Empresa_Id2"
                                        class="select-size-sm"
                                        v-model="filter2.precioEmpresaId"
                                        :options="priceList2"
                                        :reduce="option => option.precio_Empresa_Id" 
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="true"
                                        @input="selectPriceList2">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="2" class="mt-2">
                                <b-button size="sm" variant="primary" @click="getArticles2">Buscar</b-button>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            
            <b-row>
                <b-col cols="12">
                    <b-card no-body class="mb-10">
                        <b-card-header v-if="articles.length">
                            <table class="table_ " style="width: 100%;">
                                <thead>
                                    <tr>
                                        <!-- <td colspan="5">
                                            <b-form-checkbox v-if="articles.length && filter.category_key == 'prices'" v-model="noRelationated" class="custom-control-primary">
                                                Mostrar no relacionados
                                            </b-form-checkbox>
                                        </td>
                                        <td>
                                            <b-form-checkbox v-if="articles2.length && filter2.category_key == 'prices'" v-model="noRelationated2" class="custom-control-primary">
                                                Mostrar no relacionados
                                            </b-form-checkbox>
                                        </td> -->
                                        <td colspan="4">
                                            <b-form-group label="Filtros" label-class="font-weight-bold">
                                                <b-form-radio-group
                                                    v-model="valueOption"
                                                    :options="filterOptions"
                                                    name="radio-validation"
                                                    stacked>
                                                </b-form-radio-group>
                                            </b-form-group>
                                        </td>
                                        <td colspan="4">
                                            <b-form-group label="Estatus" label-class="font-weight-bold">
                                                <b-form-checkbox
                                                    v-model="estatus"
                                                    v-for="option in options"
                                                    :key="option.value"
                                                    :value="option.value"
                                                    name="flavour-4a"
                                                    stacked
                                                >
                                                    {{ option.text }}
                                                </b-form-checkbox>
                                            </b-form-group>
                                        </td>
                                        <td colspan="4" class="text-right">
                                            <b-button v-if="articles.length > 0 && articles2.length > 0" size="sm" @click="getOnAllConnections" variant="outline-primary" class="mr-1"
                                                v-b-tooltip.hover.top="'Consultar en ambas conexiones'">
                                                Buscar Articulos
                                            </b-button>

                                            <!-- <b-button v-if="articles2.length" size="sm" @click="getArticles2" variant="outline-primary" class="mr-1">
                                                Actualizar Conexión 2
                                            </b-button> -->

                                            <b-button v-if="articles.length" size="sm" @click="exportToExcel" variant="secondary">
                                                <feather-icon icon="DownloadIcon" />
                                                Descargar
                                            </b-button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="5" class="text-center_">
                                            <div v-if="filter.category_key == 'prices'">
                                                <h5 class="text-uppercase">
                                                    Lista de Articulos de {{ currentConnection ? currentConnection.nombre : '' }}
                                                </h5>
                                                <small><strong>Registros:</strong> {{ articles.length }}, </small>
                                                <small><strong>N:N:</strong> {{ firstNumNoRelationed }}, </small>
                                                <small><strong>N:R:</strong> {{ firstNumRelationated }}</small>
                                            </div>
                                        </td>
                                        <td colspan="5" class="text-uppercase">
                                            <div v-if="filter2.category_key == 'prices'">
                                                <h5 v-if="articles2.length">
                                                    Lista de Articulos de {{ secondaryConnections ? secondaryConnections.nombre : '' }}
                                                </h5>
                                                <small v-if="articles2.length"><strong>Registros:</strong> {{ articles2.length }}, </small>
                                                <small v-if="articles2.length"><strong>N:N:</strong> {{ firstNumNoRelationed2 }}, </small>
                                                <small v-if="articles2.length"><strong>N:R:</strong> {{ firstNumRelationated2 }}</small>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                            </table>
                        </b-card-header>
                        
                        <!-- Table Container -->
                        <div ref="tableContainer" class="mb-0_ table-responsive" :class="articles.length > 0 ? 'b-table-sticky-header' : ''" @scroll="handleScroll">
                            <table ref="refListTable" class="table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single table-small text-small small">
                                <thead>
                                    <tr>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('articulo_Id')">Articulo ID</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('nombre')">Articulo</th> 
                                        <th v-if="filter.category_key === 'lines'" style="font-size: 10px; cursor: pointer;" @click="sort('linea_Articulo')">Linea</th> 
                                        <th v-if="filter.category_key === 'primary_key'" style="font-size: 10px; cursor: pointer;" @click="sort('clave_Principal')">Clave Principal</th> 
                                        <th v-if="filter.category_key === 'supplier_key'" style="font-size: 10px; cursor: pointer;" @click="sort('clave_Proveedor')">Clave Proveedor</th> 
                                        <th v-if="filter.category_key === 'bar_code'" style="font-size: 10px; cursor: pointer;" @click="sort('cod_Barras')">Código Barras</th>

                                        <th v-if="filter.category_key === 'levels'" style="font-size: 10px; cursor: pointer;" @click="sort('inventario_Minimo')">Inv. Minimo</th>
                                        <th v-if="filter.category_key === 'levels'" style="font-size: 10px; cursor: pointer;" @click="sort('punto_Reorden')">Punto Reorden</th>
                                        <th v-if="filter.category_key === 'levels'" style="font-size: 10px; cursor: pointer;" @click="sort('inventario_Maximo')">Inv. Máximo</th>

                                        <!-- Datos Particulares -->
                                        <th v-if="filter.category_key === 'particular_data'" style="font-size: 10px; cursor: pointer;" @click="sort('particular_data')">
                                            {{ firstAttributeKey }}
                                        </th>

                                        <th v-if="filter.category_key === 'classificator'" style="font-size: 10px; cursor: pointer;" @click="sort('clasificacion')">Clasificación</th>
                                        <th v-if="filter.category_key === 'classificator'" style="font-size: 10px; cursor: pointer;" @click="sort('clasificacion_Valor')">Clasificación Valor</th>

                                        <!-- Precio -->
                                        <th v-if="filter.category_key === 'prices'" style="font-size: 10px; cursor: pointer;" @click="sort('price')">Precio Lista</th>

                                        <!-- Imagenes -->
                                        <th v-if="filter.category_key === 'images'" style="font-size: 10px; cursor: pointer;" @click="sort('images')">Imagen</th>
                                        
                                        <!-- Connection 2 -->
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('articulo_Id2')">Articulo ID</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('nombre2')">Articulo</th> 
                                        <th v-if="filter2.category_key === 'lines'" style="font-size: 10px; cursor: pointer;" @click="sort('linea_Articulo2')">Linea</th>
                                        <th v-if="filter2.category_key === 'primary_key'" style="font-size: 10px; cursor: pointer;" @click="sort('clave_Principal2')">Clave Principal</th>
                                        <th v-if="filter2.category_key === 'supplier_key'" style="font-size: 10px; cursor: pointer;" @click="sort('clave_Proveedor2')">Clave Proveedor</th>
                                        <th v-if="filter2.category_key === 'bar_code'" style="font-size: 10px; cursor: pointer;" @click="sort('cod_Barras2')">Código Barras</th>

                                        <th v-if="filter2.category_key === 'levels'" style="font-size: 10px; cursor: pointer;" @click="sort('inventario_Minimo2')">Inv. Minimo</th>
                                        <th v-if="filter2.category_key === 'levels'" style="font-size: 10px; cursor: pointer;" @click="sort('punto_Reorden2')">Punto Reorden</th>
                                        <th v-if="filter2.category_key === 'levels'" style="font-size: 10px; cursor: pointer;" @click="sort('inventario_Maximo2')">Inv. Máximo</th>

                                        <!-- Datos Particulares -->
                                        <th v-if="filter2.category_key === 'particular_data'" style="font-size: 10px; cursor: pointer;" @click="sort(secondAttributeKey)">
                                            {{ secondAttributeKey }}
                                        </th>

                                        <th v-if="filter2.category_key === 'classificator'" style="font-size: 10px; cursor: pointer;" @click="sort('clasificacion2')">Clasificación</th>
                                        <th v-if="filter2.category_key === 'classificator'" style="font-size: 10px; cursor: pointer;" @click="sort('clasificacion_Valor2')">Clasificación Valor</th>

                                        <!-- Prices List -->
                                        <th v-if="filter2.category_key === 'prices'" style="font-size: 10px; cursor: pointer;" @click="sort('price2')">Precio Lista</th>

                                        <!-- Imagenes -->
                                        <th v-if="filter.category_key === 'images'" style="font-size: 10px; cursor: pointer;" @click="sort('images')">Imagen</th>

                                        <!-- Son diferentes -->
                                        <th style="font-size: 10px;">Coincidencia</th>
                                        
                                    </tr>
                                </thead>
                                <tbody v-if="visibleItems.length > 0">
                                    <tr v-for="(articulo, index) in visibleItems" :key="index" @click="selectRow(articulo, index)"
                                        :class="{'selected-row': isSelected(index),}">
                                        <td style="font-size: 10px;" class="td-clave-articulo">{{ articulo.articulo_Id }}</td>
                                        <td style="font-size: 10px;">{{ articulo.nombre }}</td>
                                        <td v-if="filter.category_key === 'lines'" style="font-size: 10px;">{{ articulo.linea_Articulo }}</td>
                                        <td v-if="filter.category_key === 'primary_key'" style="font-size: 10px;">{{ articulo.clave_Principal }}</td>
                                        <td v-if="filter.category_key === 'supplier_key'" style="font-size: 10px;">{{ articulo.clave_Proveedor }}</td>
                                        <td v-if="filter.category_key === 'bar_code'" style="font-size: 10px;">{{ articulo.codigo_Barras }}</td>

                                        <td v-if="filter.category_key === 'levels'" style="font-size: 10px;">{{ articulo.inventario_Minimo }}</td>
                                        <td v-if="filter.category_key === 'levels'" style="font-size: 10px;">{{ articulo.punto_Reorden }}</td>
                                        <td v-if="filter.category_key === 'levels'" style="font-size: 10px;">{{ articulo.inventario_Maximo }}</td>

                                        <!-- Datos Particulaes -->
                                        <td v-if="filter.category_key === 'particular_data'" style="font-size: 10px;">
                                            {{ firstAttributeValue(articulo) }}
                                        </td>

                                        <!-- Clasificación y valor clasificación -->
                                        <td v-if="filter.category_key === 'classificator'" style="font-size: 10px;">
                                            {{ articulo.clasificacion }}
                                        </td>

                                        <td v-if="filter.category_key === 'classificator'" style="font-size: 10px;">
                                            {{ articulo.clasificacion_Valor }}
                                        </td>

                                        <td v-if="filter.category_key === 'prices'" style="font-size: 10px;">
                                            {{ articulo.precio }}
                                        </td>

                                        <!-- Imagenes -->
                                        <td v-if="filter.category_key === 'images'" style="font-size: 10px;">
                                            <img :src="articulo.imagen" alt="Imagen" style="width: 50px; height: 50px;">
                                        </td>
                                        
                                        <!-- Connection 2 -->
                                        <td style="font-size: 10px;" class="td-clave-articulo">{{ articulo.articulo_Id2 }}</td>
                                        <td style="font-size: 10px;">{{ articulo.nombre2 }}</td>
                                        <td v-if="filter2.category_key === 'lines'" style="font-size: 10px;">{{ articulo.linea_Articulo2 }}</td>
                                        <td v-if="filter2.category_key === 'primary_key'" style="font-size: 10px;">{{ articulo.clave_Principal2 }}</td>
                                        <td v-if="filter2.category_key === 'supplier_key'" style="font-size: 10px;">{{ articulo.clave_Proveedor2 }}</td>
                                        <td v-if="filter2.category_key === 'bar_code'" style="font-size: 10px;">{{ articulo.codigo_Barras2 }}</td>
                                        <td v-if="filter2.category_key === 'levels'" style="font-size: 10px;">{{ articulo.inventario_Minimo2 }}</td>
                                        <td v-if="filter2.category_key === 'levels'" style="font-size: 10px;">{{ articulo.punto_Reorden2 }}</td>
                                        <td v-if="filter2.category_key === 'levels'" style="font-size: 10px;">{{ articulo.inventario_Maximo2 }}</td>

                                        <!-- Datos Particulaes -->
                                        <td v-if="filter2.category_key === 'particular_data'" style="font-size: 10px;">
                                            {{ secondAttributeValue(articulo) }}
                                        </td>

                                         <!-- Clasificación y valor clasificación -->
                                         <td v-if="filter2.category_key === 'classificator'" style="font-size: 10px;">
                                            {{ articulo.clasificacion2 }}
                                        </td>

                                        <td v-if="filter2.category_key === 'classificator'" style="font-size: 10px;">
                                            {{ articulo.clasificacion_Valor2 }}
                                        </td>

                                        <td v-if="filter2.category_key === 'prices'" style="font-size: 10px;">
                                            {{ articulo.precio2 }}
                                        </td>

                                        <td>
                                            <!-- {{ validarValores(articulo) }} -->
                                            {{ articulo.coincidencia }}
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="10" class="text-center">
                                            <h6 class="mt-2">No hay registros</h6>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-card>
                </b-col>
            </b-row>

            <b-row v-if="articles.length > 0 && articles2.length > 0">
                <b-col cols="12">
                    <div class="demo-inline-spacing float-right buy-now">
                        <b-button v-if="isLinked" @click="unlinkedRelationShip" size="sm" variant="danger">
                            <feather-icon icon="Link2Icon" size="16" />
                            Desvincular
                        </b-button>

                        <b-button @click="isActiveModal = true" variant="primary">
                            <feather-icon icon="LinkIcon" size="16" />
                            Vincular
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-overlay>

        <!-- Modal  -->
        <b-modal ref="relationModal" id="relationModal" no-close-on-backdrop
            title-class="font-weight-bold" title="Vincular Articulo Por Clave Principal" hide-footer @cancel="isActiveModal = false" 
            @hidden="closeModal" :visible="isActiveModal" size="lg">
            <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
                <b-row>
                    <b-col cols="4">
                        <b-form-group label="Clave Articulo" :description="`DE ${currentConnection ? currentConnection.nombre : ''}`" label-for="article_Id" label-class="font-weight-bold">
                            <b-form-input
                                v-model="claveArticulo"
                                size="sm"
                                id="article_Id"
                                placeholder="Clave Articulo Origen"
                                @keydown.enter="addInCollection"/>
                        </b-form-group>                    
                    </b-col>

                    <b-col cols="4">
                        <b-form-group label="Clave Articulo" :description="`DE ${secondaryConnections ? secondaryConnections.nombre : ''}`" label-for="article_Id_2" label-class="font-weight-bold">
                            <b-form-input
                                v-model="claveArticulo2"
                                size="sm"
                                id="article_Id_2"
                                placeholder="Clave Articulo Destino"
                                @keydown.enter="addInCollection"/>
                        </b-form-group>                    
                    </b-col>

                    <b-col cols="4">
                        <b-button :disabled="loading || claveArticulo == '' || claveArticulo2 == ''" @click="addInCollection" size="sm" variant="primary" class="mt-2">
                            <feather-icon icon="PlusIcon" size="16" />
                            Agregar
                        </b-button>
                    </b-col>
                </b-row>

                
                <table class="table table-border table-hover b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single table-small text-small small">
                    <thead>
                        <tr>
                            <th style="font-size: 10px;" class="text-center" colspan="2">{{ currentConnection ? currentConnection.nombre : '' }}</th>
                            <th style="font-size: 10px;" class="text-center" colspan="2">{{ secondaryConnections ? secondaryConnections.nombre : '' }}</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th style="font-size: 10px;">Clave</th>
                            <th style="font-size: 10px;">Articulo</th>
                            <th style="font-size: 10px;">Clave</th>
                            <th style="font-size: 10px;">Articulo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                            <td style="font-size: 10px">{{ item.clave_Matriz }}</td>
                            <td style="font-size: 10px">{{ item.articulo_Matriz }}</td>
                            <td style="font-size: 10px">{{ item.clave_Concretos }}</td>
                            <td style="font-size: 10px">{{ item.articulo_Concretos}}</td>
                            <td>
                                <b-button size="sm" variant="danger" @click="items.splice(index, 1)">
                                    <feather-icon icon="TrashIcon" size="16" />
                                </b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <b-row>
                    <b-col cols="12" class="float-rigth text-right">
                        <b-button :disabled="loading || items.length === 0" @click="saveRelations" size="sm" variant="primary" class="mt-2">
                            <feather-icon icon="SaveIcon" size="16" />
                            Guardar Relaciones
                        </b-button>
                    </b-col>
                </b-row>
                
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BCardFooter, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab, BFormRadioGroup, BFormRadio, BFormInvalidFeedback, BFormValidFeedback,
    BTooltip, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import vSelect from "vue-select";
import { RecycleScroller } from 'vue-virtual-scroller';
import * as XLSX from 'xlsx';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

import ArticlesProvider from '@/providers/Articles'
const ArticlesResource = new ArticlesProvider()

import PricesArticlesProvider from '@/providers/PricesArticles'
const PricesArticlesResource = new PricesArticlesProvider()

import ConnectionsProvider from '@/providers/Connections'
const ConnectionsResource = new ConnectionsProvider()

import WareHouseProvider from '@/providers/WareHouses'
const WareHouseResource = new WareHouseProvider()

import AttributesProvider from '@/providers/Attributes'
const AttributesResource = new AttributesProvider()

import CatalogClassifiersProvider from '@/providers/CatalogClassifiers'
const CatalogClassifiersResource = new CatalogClassifiersProvider()

export default {
    name: 'Relations',
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BCardFooter,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormRadioGroup, 
        BFormRadio, 
        BFormInvalidFeedback, 
        BFormValidFeedback,
        BFormTextarea,
        BFormTags,
        BAvatar,
        BPagination,
        BFormSelect,
        BBadge,
        BCardSubTitle,
        BCardTitle,
        BTabs,
        BTab,
        BTooltip,
        VBTooltip,

        //
        BCardActions,
        vSelect,
        RecycleScroller,
        FeatherIcon,
    },
    directives: {
        "b-tooltip": VBTooltip,
        "b-toggle": VBToggle,
    },
    data() {
        return {
            pricesList: [],        // Lista de precios
            priceList2: [],        // Lista de precios de la conexión secundaria
            articles: [],          // Lista de artículos
            articles2: [],         // Lista de artículos de la conexión secundaria
            searchQuery: '',       // Consulta de búsqueda
            pageNumber: 1,         // Número de página actual
            pageSize: 1000,          // Tamaño de página
            loading: false,        // Indicador de carga
            items: [],             // Lista de artículos a vincular
            filter : {
                idEmpresa: null,
                precioEmpresaId: null,
                category_key: null,
                warehouse_id: null,
                attribute_id: null,
                classificator_id: null,
                valor_clasif_id: null,
                idClasificador: null,
                idClasificadorValor: null,
                idPrecioLista: null,
            },
            filter2 : {
                idEmpresa: null,
                precioEmpresaId: null,
                category_key: null,
                warehouse_id: null,
                attribute_id: null,
                classificator_id: null,
                valor_clasif_id: null,
                estatus: [],
            },
            selectedRowIndex: -1,      // Índice seleccionado
            isActiveModal: false,      // Indicador de modal activo
            isLinked: false,
            relation: {},
            sortKey: '', // Clave para ordenar
            sortOrder: 1, // 1 para ascendente, -1 para descendente
            itemsPerPage: 500, // Número de artículos cargados por bloque
            currentPage: 1,
            visibleItems: [],
            claveArticulo: '',
            claveArticulo2: '',
            noRelationated: false,
            noRelationated2: false,
            categories: [
                // {   key: 'prices', label: 'Lista Precios', subquery: false },
                {   key: 'lines', label: 'Lineas',  subquery: false},
                {   key: 'classificator', label: 'Clasificadores', subquery: true },
                {   key: 'particular_data', label: 'Datos Particulares', subquery: true },
                {   key: 'levels', label: 'Niveles', subquery: true },
                {   key: 'names', label: 'Nombres', subquery: false },
                {   key: 'primary_key', label: 'Clave Principal', subquery: false },
                {   key: 'supplier_key', label: 'Clave Proveedor', subquery: false },
                {   key: 'bar_code', label: 'Código Barras', subquery: false },
                {   key: 'images', label: 'Imágenes', subquery: false },
            ],
            warehouses: [],
            warehouses2: [],
            attributes: [],
            attributes2: [],
            propertyMapper: {}, // Mapeador dinámico de propiedades
            classificators: [],
            classificators2: [],
            classificatorsValues: [],
            classificatorsValues2: [],
            filterOptions: [
                { text: 'Todos', value: '' },
                { text: 'Mostrar Iguales', value: 'Igual' },
                { text: 'Mostrar Diferentes', value: 'Diferente' },
                { text: 'Mostrar nulo en conexíon 1', value: 'Nulo 1' },
                { text: 'Mostrar nulo en conexíon 2', value: 'Nulo 2' },
            ],
            valueOption: "",
            options: [
                { text: 'Activo', value: 'A' },
                { text: 'Suspensión de Compras', value: 'C' },                
                { text: 'Suspensión de Ventas', value: 'V' },
                { text: 'Suspensión Compras/Ventas', value: 'S' },
                { text: 'Baja', value: 'B' },
            ],
            estatus: ['A', 'C', 'V', 'S'],
        }
    },
    computed: {
        state() {
            return Boolean(this.value)
        },
        firstAttributeKey () {
            return this.attributes.find(item => item.atributo_Id === this.filter.attribute_id)?.nombre || ''
        },
        secondAttributeKey () {
            return this.attributes2.find(item => item.atributo_Id === this.filter2.attribute_id)?.nombre || ''
        },
        mdColumn() {
            return this.filter.category_key === 'levels' ? 6 : 5
        },
        currentConnectionId() {
            return store.getters['auth/getConexionId']
        },
        connections() {
            return store.getters['auth/getConnections'] //.filter(item => item.idEmpresa !== this.currentConnectionId)
        },
        currentConnection() {
            return store.getters['auth/getConnections'].find(item => item.idEmpresa === this.filter.idEmpresa)
        },
        secondaryConnections() {
            return store.getters['auth/getConnections'].find(item => item.idEmpresa == this.filter2.idEmpresa)
        },
       
        // Computed property para unificar los artículos de ambas empresas       
        oLFarticulosCombinados() {
            const combinedMap = new Map();

            // Función auxiliar para inicializar un artículo combinado
            const inicializarArticuloCombinado = (articulo, esEmpresa1 = true) => {
                const prefix = esEmpresa1 ? '' : '2';
                return {
                    [`articulo_Id${prefix}`]: articulo.articulo_Id,
                    [`nombre${prefix}`]: articulo.nombre,
                    [`linea_Articulo_Id${prefix}`]: articulo.linea_Articulo_Id,
                    [`linea_Articulo${prefix}`]: articulo.linea_Articulo,
                    [`clave_Principal${prefix}`]: articulo.clave_Principal,
                    [`clave_Proveedor${prefix}`]: articulo.clave_Proveedor,
                    [`codigo_Barras${prefix}`]: articulo.codigo_Barras,
                    [`imagen${prefix}`]: articulo.imagen,
                    [`rel_Clave_Id${prefix}`]: articulo.rel_Clave_Id,
                    [`rel_Clave_Id_Matriz${prefix}`]: articulo.rel_Clave_Id_Matriz,
                    [`rel_Clave_Id_Concretos${prefix}`]: articulo.rel_Clave_Id_Concretos,
                    [`rel_Clave_Id_Orlo${prefix}`]: articulo.rel_Clave_Id_Orlo,
                    [`inventario_Minimo${prefix}`]: articulo.inventario_Minimo,
                    [`punto_Reorden${prefix}`]: articulo.punto_Reorden,
                    [`inventario_Maximo${prefix}`]: articulo.inventario_Maximo,
                    [`reorden_automatico${prefix}`]: articulo.reorden_Automatico,
                    [`precio_mayoreo${prefix}`]: articulo.precio_Mayoreo,
                    [`cant_etiquetas_matriz${prefix}`]: articulo.cant_Etiquetas_Matriz,
                    [`cant_etiquetas_barda${prefix}`]: articulo.cant_Etiquetas_Barda,
                    [`cant_etiquetas_papalote${prefix}`]: articulo.cant_Etiquetas_Papalote,
                    [`cant_etiquetas_juarez${prefix}`]: articulo.cant_Etiquetas_Juarez,
                    [`incluir_en_catalogo_de_caja${prefix}`]: articulo.incluir_En_Catalogo_De_Caja,
                    [`incluir_en_marbetes${prefix}`]: articulo.incluir_En_Marbetes,
                    [`clasificacion${prefix}`]: articulo.clasificacion,
                    [`clasificacion_Valor${prefix}`]: articulo.clasificacion_Valor,
                    [`precio${prefix}`]: articulo.precio,
                    [`ultimo_Costo${prefix}`]: articulo.ultimo_Costo,
                };
            };

            // Mapeo de artículos de la empresa 1
            this.articles.forEach(articulo => {
                const articuloCombinado = {
                    ...inicializarArticuloCombinado(articulo, true),
                    ...inicializarArticuloCombinado({}, false) // Inicializa los campos de la empresa 2 vacíos
                };
                combinedMap.set(articulo.rel_Clave_Id_Concretos, articuloCombinado);
            });

            // Mapeo de artículos de la empresa 2
            this.articles2.forEach(articulo => {
                const existente = combinedMap.get(articulo.rel_Clave_Id_Concretos);
                if (existente) {
                    Object.assign(existente, inicializarArticuloCombinado(articulo, false));
                } else {
                    const articuloCombinado = {
                        ...inicializarArticuloCombinado({}, true), // Inicializa los campos de la empresa 1 vacíos
                        ...inicializarArticuloCombinado(articulo, false)
                    };
                    combinedMap.set(articulo.rel_Clave_Id_Concretos, articuloCombinado);
                }
            });

            // Convertir el Map a un array y agregar la propiedad coincidencia
            return Array.from(combinedMap.values()).map(item => ({
                ...item,
                coincidencia: this.validarValores(item)
            }));
        },
        articulosCombinados() {
            let combined = [];  

            // Mapeo de artículos de la empresa 1
            this.articles.forEach(articulo => {
                let articuloCombinado = {   
                    
                    articulo_Id: articulo.articulo_Id,
                    nombre: articulo.nombre,
                    linea_Articulo_Id: articulo.linea_Articulo_Id,
                    linea_Articulo: articulo.linea_Articulo,
                    clave_Principal: articulo.clave_Principal,
                    clave_Proveedor: articulo.clave_Proveedor,
                    codigo_Barras: articulo.codigo_Barras,
                    imagen: articulo.imagen,
                    rel_Clave_Id: articulo.rel_Clave_Id,
                    rel_Clave_Id_Matriz: articulo.rel_Clave_Id_Matriz,
                    rel_Clave_Id_Concretos: articulo.rel_Clave_Id_Concretos,
                    rel_Clave_Id_Orlo: articulo.rel_Clave_Id_Orlo,
                    inventario_Minimo: articulo.inventario_Minimo,
                    punto_Reorden: articulo.punto_Reorden,
                    inventario_Maximo: articulo.inventario_Maximo,

                    reorden_automatico: articulo.reorden_Automatico,
                    precio_mayoreo: articulo.precio_Mayoreo,
                    cant_etiquetas_matriz: articulo.cant_Etiquetas_Matriz,
                    cant_etiquetas_barda: articulo.cant_Etiquetas_Barda,
                    cant_etiquetas_papalote: articulo.cant_Etiquetas_Papalote,
                    cant_etiquetas_juarez: articulo.cant_Etiquetas_Juarez,
                    incluir_en_catalogo_de_caja: articulo.incluir_En_Catalogo_De_Caja,
                    incluir_en_marbetes: articulo.incluir_En_Marbetes,
                    clasificacion: articulo.clasificacion,
                    clasificacion_Valor: articulo.clasificacion_Valor,
                    precio: articulo.precio,
                    ultimo_Costo: articulo.ultimo_Costo,
                    
                    articulo_Id2: '',
                    nombre2: '',
                    linea_Articulo_Id2: '',
                    linea_Articulo2: '',
                    clave_Principal2: '',
                    clave_Proveedor2: '',
                    codigo_Barras2: '',
                    imagen2: '',
                    rel_Clave_Id2: '',
                    rel_Clave_Id_Matriz2: '',
                    rel_Clave_Id_Concretos2: '',
                    rel_Clave_Id_Orlo2: '',
                    inventario_Minimo2: '',
                    punto_Reorden2: '',
                    inventario_Maximo2: '',
                    reorden_automatico2: '',
                    precio_mayoreo2: '',
                    cant_etiquetas_matriz2: '',
                    cant_etiquetas_barda2: '',
                    cant_etiquetas_papalote2: '',
                    cant_etiquetas_juarez2: '',
                    incluir_en_catalogo_de_caja2: '',
                    incluir_en_marbetes2: '',
                    clasificacion2: '',
                    clasificacion_Valor2: '',
                    precio2: '',
                    ultimo_Costo2: '',
                };
                combined.push(articuloCombinado);
            });       

            // Mapeo de artículos de la empresa 2
            this.articles2.forEach(articulo => {

                // Buscar si el artículo de empresa 2 ya existe en la lista combinada por su relación Concretos
                // let existente = combined.find(item => item.rel_Clave_Id_Concretos === articulo.rel_Clave_Id_Concretos);
                let existente = combined.find(item => {
                    return item.rel_Clave_Id_Concretos && articulo.rel_Clave_Id_Concretos &&
                        item.rel_Clave_Id_Concretos === articulo.rel_Clave_Id_Concretos;
                });

                if (existente) {
                    existente.articulo_Id2 = articulo.articulo_Id
                    existente.nombre2 = articulo.nombre
                    existente.linea_Articulo_Id2 = articulo.linea_Articulo_Id
                    existente.linea_Articulo2 = articulo.linea_Articulo
                    existente.clave_Principal2 = articulo.clave_Principal
                    existente.clave_Proveedor2 = articulo.clave_Proveedor
                    existente.codigo_Barras2 = articulo.codigo_Barras
                    existente.imagen2 = articulo.imagen
                    existente.rel_Clave_Id2 = articulo.rel_Clave_Id
                    existente.rel_Clave_Id_Matriz2 = articulo.rel_Clave_Id_Matriz
                    existente.rel_Clave_Id_Concretos2 = articulo.rel_Clave_Id_Concretos
                    existente.rel_Clave_Id_Orlo2 = articulo.rel_Clave_Id_Orlo
                    existente.inventario_Minimo2 = articulo.inventario_Minimo
                    existente.punto_Reorden2 = articulo.punto_Reorden
                    existente.inventario_Maximo2 = articulo.inventario_Maximo

                    existente.reorden_automatico2 = articulo.reorden_Automatico
                    existente.precio_mayoreo2 = articulo.precio_Mayoreo
                    existente.cant_etiquetas_matriz2 = articulo.cant_Etiquetas_Matriz
                    existente.cant_etiquetas_barda2 = articulo.cant_Etiquetas_Barda
                    existente.cant_etiquetas_papalote2 = articulo.cant_Etiquetas_Papalote
                    existente.cant_etiquetas_juarez2 = articulo.cant_Etiquetas_Juarez
                    existente.incluir_en_catalogo_de_caja2 = articulo.incluir_En_Catalogo_De_Caja
                    existente.incluir_en_marbetes2 = articulo.incluir_En_Marbetes
                    existente.clasificacion2 = articulo.clasificacion
                    existente.clasificacion_Valor2 = articulo.clasificacion_Valor
                    existente.precio2 = articulo.precio
                    existente.ultimo_Costo2 = articulo.ultimo_Costo

                } else {
                    
                    // Si no existe, lo agregamos como un nuevo elemento combinado
                    let articuloCombinado = {
                        articulo_Id: '',
                        nombre: '',
                        linea_Articulo_Id: '',
                        linea_Articulo: '',
                        clave_Principal: '',
                        clave_Proveedor: '',
                        codigo_Barras: '',
                        imagen: '',
                        rel_Clave_Id: '',
                        rel_Clave_Id_Matriz: '',
                        rel_Clave_Id_Concretos: '',
                        rel_Clave_Id_Orlo: '',
                        inventario_Minimo: '',
                        punto_Reorden: '',
                        inventario_Maximo: '',

                        reorden_automatico: '',
                        precio_mayoreo: '',
                        cant_etiquetas_matriz: '',
                        cant_etiquetas_barda: '',
                        cant_etiquetas_papalote: '',
                        cant_etiquetas_juarez: '',
                        incluir_en_catalogo_de_caja: '',
                        incluir_en_marbetes: '',
                        clasificacion: '',
                        clasificacion_Valor: '',
                        precio: '',
                        ultimo_Costo: '',

                        articulo_Id2: articulo.articulo_Id,
                        nombre2: articulo.nombre,
                        linea_Articulo_Id2: articulo.linea_Articulo_Id,
                        linea_Articulo2: articulo.linea_Articulo,
                        clave_Principal2: articulo.clave_Principal,
                        clave_Proveedor2: articulo.clave_Proveedor,
                        codigo_Barras2: articulo.codigo_Barras,
                        imagen2: articulo.imagen,
                        rel_Clave_Id2: articulo.rel_Clave_Id,
                        rel_Clave_Id_Matriz2: articulo.rel_Clave_Id_Matriz,
                        rel_Clave_Id_Concretos2: articulo.rel_Clave_Id_Concretos,
                        rel_Clave_Id_Orlo2: articulo.rel_Clave_Id_Orlo,
                        inventario_Minimo2: articulo.inventario_Minimo,
                        punto_Reorden2: articulo.punto_Reorden,
                        inventario_Maximo2: articulo.inventario_Maximo,

                        reorden_automatico2: articulo.reorden_Automatico,
                        precio_mayoreo2: articulo.precio_Mayoreo,
                        cant_etiquetas_matriz2: articulo.cant_Etiquetas_Matriz,
                        cant_etiquetas_barda2: articulo.cant_Etiquetas_Barda,
                        cant_etiquetas_papalote2: articulo.cant_Etiquetas_Papalote,
                        cant_etiquetas_juarez2: articulo.cant_Etiquetas_Juarez,
                        incluir_en_catalogo_de_caja2: articulo.incluir_En_Catalogo_De_Caja,
                        incluir_en_marbetes2: articulo.incluir_En_Marbetes,
                        clasificacion2: articulo.clasificacion,
                        clasificacion_Valor2: articulo.clasificacion_Valor,
                        precio2: articulo.precio,
                        ultimo_Costo2: articulo.ultimo_Costo,
                    };
                    combined.push(articuloCombinado);
                }
            });

            return combined.map(item => {
                return {
                    ...item,
                    coincidencia: this.validarValores(item)
                }
            });
        },      
        sortedArticulosCombinados() {
            let data = this.articulosCombinados.slice().sort((a, b) => {
                if (!this.sortKey) return 0;
                
                const aValue = a[this.sortKey];
                const bValue = b[this.sortKey];

                // Si aValue es null y bValue no, ponemos aValue primero
                if (aValue === '' && bValue !== '') return -1;
                // Si bValue es null y aValue no, ponemos bValue primero
                if (bValue === '' && aValue !== '') return 1;

                // Si ambos son null, son iguales
                if (aValue === '' && bValue === '') return 0;

                // Orden normal cuando no hay null
                if (aValue < bValue) return -1 * this.sortOrder;
                if (aValue > bValue) return 1 * this.sortOrder;
                return 0;
            });

            // Aplicar los filtros
            if (this.noRelationated) {
                return data.filter(a => a.rel_Clave_Id === -1);
            } else if (this.noRelationated2) {
                return data.filter(a => a.rel_Clave_Id2 === -1);
            }

            return data;
        },
        firstNumNoRelationed() {
            return this.articles.filter(a => a.rel_Clave_Id === -1).length
        },
        firstNumNoRelationed2() {
            return this.articles2.filter(a => a.rel_Clave_Id === -1).length
        },
        firstNumRelationated() {
            return this.articles.filter(a => a.rel_Clave_Id > 0).length
        },
        firstNumRelationated2() {
            return this.articles2.filter(a => a.rel_Clave_Id > 0).length
        }

    },
    async mounted() {
        // const tableContainer = this.$refs.tableContainer;
        // tableContainer.addEventListener('scroll', this.handleScroll);
        // this.loadArticles(); // Cargar artículos al iniciar
        // await this.getPricesList()
        // await this.getConnections()
    },
    beforeDestroy() {
        const tableContainer = this.$refs.tableContainer;
        tableContainer.removeEventListener('scroll', this.handleScroll);
    },
    methods: { 
        async getOnAllConnections() {
            Promise.all([
                this.getArticles(),
                this.getArticles2(),              
            ])
        },
        validarValores(articulo) {
            const isRelationated = articulo.rel_Clave_Id > 0 || articulo.rel_Clave_Id2 > 0;
            if (this.filter.category_key === 'lines') {
                return this.compareNullableValues(articulo.linea_Articulo,articulo.linea_Articulo2, isRelationated)
            } else if (this.filter.category_key === 'primary_key') {
                return this.compareNullableValues(articulo.clave_Principal, articulo.clave_Principal2, isRelationated)
            } else if (this.filter.category_key === 'supplier_key') {
                return this.compareNullableValues(articulo.clave_Proveedor, articulo.clave_Proveedor2, isRelationated)
            } else if (this.filter.category_key === 'bar_code') {
                return this.compareNullableValues(articulo.codigo_Barras, articulo.codigo_Barras2, isRelationated)
            } else if (this.filter.category_key === 'levels') {
                return `${articulo.inventario_Minimo} - ${articulo.punto_Reorden} - ${articulo.inventario_Maximo} === ${articulo.inventario_Minimo2} - ${articulo.punto_Reorden2} - ${articulo.inventario_Maximo2}`
            } else if (this.filter.category_key === 'particular_data') {
                return this.compareNullableValues(this.firstAttributeValue(articulo), this.secondAttributeValue(articulo), isRelationated)
            } else if (this.filter.category_key === 'classificator') {
                return this.compareNullableValues(articulo.clasificacion_Valor, articulo.clasificacion_Valor2, isRelationated)
            } else if (this.filter.category_key === 'prices') {
                return this.compareNullableValues(articulo.precio, articulo.precio2, isRelationated)
            } else if (this.filter.category_key === 'images') {
                return this.compareNullableValues(articulo.imagen, articulo.imagen2, isRelationated)
            } else if (this.filter.category_key === 'names') {
                return this.compareNullableValues(articulo.nombre, articulo.nombre2, isRelationated)
            }
        },
        compareNullableValues(value1, value2, isRelationated) {
            if (isRelationated) {
                // Si alguno de los valores es nulo, se considera "Diferente"
                if (value1 === null || value1 === undefined || value1 === '') {
                    return 'Diferente';
                }
                if (value2 === null || value2 === undefined || value2 === '') {
                    return 'Diferente';
                }
                // Si ambos valores no son nulos, se comparan
                return value1 === value2 ? 'Igual' : 'Diferente';
            }
            
            if (value1 === null || value1 === undefined || value1 === '') {
                return 'Nulo 1';
            }
            if (value2 === null || value2 === undefined || value2 === '') {
                return 'Nulo 2';
            }
            return value1 === value2 ? 'Igual' : 'Diferente';
        },
        async selectClassificator(evt) {
            // await this.getClassificatorByValues(this.filter.idEmpresa, evt)
        },
        async selectClassificator2(evt) {
            // await this.getClassificatorByValues(this.filter2.idEmpresa, evt)
        },
        async getClassificatorByValues(idEmpresa, id) {
            try {
                this.loading = true
                const { data } = await CatalogClassifiersResource.getByCompanyIdAndClassifierId(idEmpresa, id)
                this.loading = false
                if (data.isSuccesful) {
                    if (idEmpresa == 1) {
                        this.classificatorsValues = data.data
                    } else {
                        this.classificatorsValues2 = data.data
                    }
                } else {
                    this.danger(data.message)
                }

            }catch(e) {
                this.handleResponseErrors(e)
            }finally {
                this.loading = false
            }
        },
        async getClassificators(idEmpresa) {

            try {
                this.loading = true
                const { data } = await CatalogClassifiersResource.findByCompanyId(idEmpresa)
                
                if (data.isSuccesful) {
                    if (idEmpresa == 1) {
                        this.classificators = data.data
                    } else {
                        this.classificators2 = data.data
                    }
                } else {
                    this.danger(data.message)
                }

            }catch(e) {
                this.handleResponseErrors(e)
            }finally {
                this.loading = false
            }
        },
        selectParticularData() {
            this.filter2 = {
                idEmpresa: null,
                precioEmpresaId: null,
                category_key: null,
                warehouse_id: null,
                attribute_id: null,
                classificator_id: null,
                valor_clasif_id: null,
                idClasificador2: null,
                idClasificadorValor2: null,
                idPrecioLista2: null,
            }
        },
        firstAttributeValue(item) {
            const attribute = this.attributes.find(item => item.atributo_Id === this.filter.attribute_id)
            if (attribute) {
                //Convertir todo a misnúsculas los nombres de los atributos
                const attributeName = attribute.nombre.toLowerCase()
                const attributeValue = item[attributeName]
                return attributeValue
            }
            return ''
        },
        secondAttributeValue(item) {
            const attribute = this.attributes2.find(item => item.atributo_Id === this.filter2.attribute_id)
            if (attribute) {
                //Convertir todo a misnúsculas los nombres de los atributos
                const attributeName = attribute.nombre.toLowerCase()
                const attributeValue = item[`${attributeName}2`]
                return attributeValue
            }
            return ''
        },
        async getAttributes(idEmpresa) {

            const query = {
                idEmpresa: idEmpresa,
                objeto: 'ARTICULOS'
            }

            try {
                this.loading = true
                const { data } = await AttributesResource.index(query)
                this.loading = false
                if (data.isSuccesful) {
                    if (idEmpresa == 1) {
                        this.attributes = data.data
                    } else {
                        this.attributes2 = data.data
                    }                    
                } else {
                    this.danger(data.message)
                }
            }catch(error) {
                this.handleResponseErrors(error)
            } finally {
                this.loading = false
            }
        },
        async exportToExcel() {
            this.loading = true
            this.currentPage = 1
            this.visibleItems = this.sortedArticulosCombinados    
            
            await this.$nextTick()

            const table = this.$refs.refListTable;
            const wb = XLSX.utils.table_to_book(table);
            XLSX.writeFile(wb, 'Relaciones.xlsx');

            await this.$nextTick();

            const end = 1 * this.itemsPerPage;
            this.visibleItems = this.sortedArticulosCombinados.slice(0, end);

            this.loading = false
        },
        async getConnections () {
            this.loading = true
            const { data } = await ConnectionsResource.index()
            this.loading = false
        },
        async findByPrimaryKey() {
            try {
                this.loading = true
                const { data } = await ArticlesResource.findByPrimaryKey(this.relation.relClaveId)
                this.loading = false
                if (data.isSuccesful) {
                    this.relation = data.data
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        handleScroll2(event) {
            const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
                if (bottom) {
                    this.currentPage++;
                    this.loadItems();
                }
        },
        loadItems() {
            // const start = (this.currentPage - 1) * this.itemsPerPage;
            // const end = this.currentPage * this.itemsPerPage;
            // this.loading = true;
            const items = this.sortedArticulosCombinados //.slice(0, end);
            this.visibleItems = this.valueOption == "" ? items : items.filter(i => i.coincidencia === this.valueOption);
            // this.loading = false;
        },      
        async sort(key) {
            this.loading = true; // Mostrar el spinner de carga

            this.relation = {}
            this.selectedRowIndex = -1
            
            // Esperar a que la UI actualice el estado del spinner
            await this.$nextTick();
            this.sortOrder = 1;

            if (this.sortKey === key) {
                this.sortOrder = -this.sortOrder; // Cambiar la dirección del orden
            } else {
                this.sortKey = key; // Ordenar por una nueva columna
            }

            // Simular un retraso para la carga, puede eliminarse en un caso real
            await new Promise(resolve => setTimeout(resolve, 500));

            this.loading = false; // Ocultar el spinner
        },
        closeModal() {
            this.isActiveModal = false
        },
        selectRow(item, index) {
            this.relacion = {}
            this.selectedRowIndex = index
            this.relation = item
            if (item && item.rel_Clave_Id && item.rel_Clave_Id != "" && item.rel_Clave_Id != 0 && item.rel_Clave_Id != null && item.rel_Clave_Id != -1) {
                this.isLinked = true
            } else {
                this.isLinked = false
            }
        },
        isSelected(index) {
            // Determina si la fila en el índice dado está seleccionada
            return index === this.selectedRowIndex
        },
        async selectConnection2(evt) {
            this.articles2 = []
            this.priceList2 = []
            this.selectedRowIndex = -1
            const idEmpresa = evt
            if (idEmpresa) {
                // await this.getPricesList2(idEmpresa)
            }
        },
        async selectConnection(evt) {
            const idEmpresa = evt
            this.pricesList = []
            this.priceList2 = []
            this.articles = []
            this.articles2 = []
            this.pageNumber = 1
            this.selectedRowIndex = -1
            this.filter.precioEmpresaId = null
            this.filter.category_key = null
            this.filter2.idEmpresa = null
            this.filter2.precioEmpresaId = null
            this.filter2.category_key = null
            if (idEmpresa) {
                // await this.getPricesList(idEmpresa)
            }
        },
        async selectSearchCategory(evt) {
            this.articles = []
            this.articles2 = []
            this.pageNumber = 1
            this.selectedRowIndex = -1
            
            this.filter.precioEmpresaId = null
            this.filter.warehouse_id = null
            this.filter.attribute_id = null
            this.filter.classificator_id = null
            this.filter.valor_clasif_id = null
            this.filter.idClasificador = null
            this.filter.idClasificadorValor = null
            this.filter.idPrecioLista = null

            if (evt === 'levels') {
                await this.getWarehouses(this.filter.idEmpresa)  
            } else if (evt === 'particular_data') {
                await this.getAttributes(this.filter.idEmpresa)
            } else if (evt == 'classificator') {
                await this.getClassificators(this.filter.idEmpresa)
            } else if (evt == 'prices') {
                await this.getPricesList(this.filter.idEmpresa)
            }
        },
        async selectPriceList(evt) {
            this.articles = []
            this.articles2 = []
            this.pageNumber = 1
            this.selectedRowIndex = -1

            this.filter = {
                precioEmpresaId: null,
                warehouse_id: null,
                attribute_id: null,
            //     classificator_id: null,
            //     valor_clasif_id: null,
            //     idClasificador: null,
            //     idClasificadorValor: null,
            //     idPrecioLista: null,
            }           

            if (evt === 'levels') { //Load warehouses
                await this.getWarehouses(this.filter.idEmpresa)  
            } else if (evt === 'particular_data') {
                await this.getAttributes(this.filter.idEmpresa)
            } else if (evt == 'classificator') {
                await this.getClassificators(this.filter.idEmpresa)
            } else if (evt == 'prices') {
                await this.getPricesList(this.filter.idEmpresa)
            }
        },
        async selectPriceList2(evt) {
            this.articles2 = []
            this.pageNumber = 1
            this.selectedRowIndex = -1

            if (evt === 'levels') { //Load warehouses
                await this.getWarehouses(this.filter2.idEmpresa)
            } else if (evt === 'particular_data') {
                await this.getAttributes(this.filter2.idEmpresa)
            } else if (evt == 'classificator') {
                await this.getClassificators(this.filter2.idEmpresa)
            } else if (evt == 'prices') {
                await this.getPricesList(this.filter2.idEmpresa)
            }
        },
        async getWarehouses(idEmpresa) {
            try {
                this.loading = true
                const { data } = await WareHouseResource.getByCompanyId(idEmpresa)
                if (data.isSuccesful) {
                    if (idEmpresa === 1) {
                        this.warehouses = data.data
                    } else {
                        this.warehouses2 = data.data
                    }
                } else {
                    this.danger(data.message)
                }                
            }catch(error) {
                this.handleResponseErrors(error)
            } finally {
                this.loading = false
            }
        },
        async getPricesList(idEmpresa) {
            try {
                this.loading = true
                const { data } = await PricesArticlesResource.getAllPricesList(idEmpresa)
                this.loading = false   
                if (idEmpresa == 1) {
                    this.pricesList = data.data
                } else {
                    this.priceList2 = data.data
                }
            }catch(error) {
                this.loading = false
                this.handleResponseErrors(error)
            } finally {
                this.loading = false
            }
        },
        // async getPricesList2(idEmpresa) {
        //     this.priceList2 = []
        //     try {
        //         this.loading = true
        //         const { data } = await PricesArticlesResource.getAllPricesList(idEmpresa)
        //         this.loading = false                
        //         this.priceList2 = data.data
        //     }catch(error) {
        //         this.loading = false
        //         console.error("Error loading prices list", error)
        //         this.handleResponseErrors(error)
        //     }
        // },
        async getArticles() {
            // Reiniciar valores
            this.selectedRowIndex = -1;
            this.articles = [];
            this.relation = {};

            // Crear el objeto query
            const query = {
                ...this.filter,
                idAlmacen: this.filter.warehouse_id,
                idDatoParticular: this.filter.attribute_id,
                idClasificador: this.filter.classificator_id,
                idClasificadorValor: this.filter.valor_clasif_id,
                idPrecioLista: this.filter.precioEmpresaId,
                search: this.searchQuery,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                estatus: this.estatus
            };

            // Eliminar propiedades innecesarias de query de forma dinámica
            const propertiesToDelete = [
                'category_key', 'warehouse_id', 'attribute_id', 
                'classificator_id', 'valor_clasif_id', 'precioEmpresaId'
            ];
            propertiesToDelete.forEach(prop => delete query[prop]);

            try {
                this.loading = true;

                // Obtener los artículos
                const { data } = await ArticlesResource.getAllGeneralArticles(query);

                // Verificar si hay datos
                if (data.data.length === 0) {
                    this.endOfData = true;
                    return;
                }

                // Mapear y agregar los artículos
                this.articles.push(...data.data.map(article => this.formatArticle(article)));

                // Incrementar el número de página
                this.pageNumber += 1;
            } catch (error) {
                this.handleResponseErrors(error);
            } finally {
                this.loading = false;
            }          
        },
        // Función auxiliar para formatear un artículo
        formatArticle(article) {
            const defaultValues = {
                rel_Clave_Id: '',
                rel_Clave_Id_Matriz: '',
                rel_Clave_Id_Concretos: '',
                rel_Clave_Id_Orlo: ''
            };

            // Asignar valores por defecto si no existen
            return {
                ...article,
                ...Object.fromEntries(
                    Object.entries(defaultValues).map(([key, defaultValue]) => [
                        key,
                        article[key] !== null && article[key] !== undefined ? article[key] : defaultValue
                    ])
                )
            };
        },
        async getArticles2() {

            // Reiniciar valores
            this.selectedRowIndex = -1;
            this.articles2 = [];
            this.relation = {};

            // Crear el objeto query
            const query = {
                ...this.filter2,
                idAlmacen: this.filter2.warehouse_id,
                idDatoParticular: this.filter2.attribute_id,
                idClasificador: this.filter2.classificator_id,
                idClasificadorValor: this.filter2.valor_clasif_id,
                idPrecioLista: this.filter.precioEmpresaId,
                search: this.searchQuery,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                estatus: this.estatus
            };

            // Eliminar propiedades innecesarias de query de forma dinámica
            const propertiesToDelete = [
                'category_key', 'warehouse_id', 'attribute_id', 
                'classificator_id', 'valor_clasif_id', 'precioEmpresaId'
            ];
            propertiesToDelete.forEach(prop => delete query[prop]);

            try {
                this.loading = true;

                // Obtener los artículos
                const { data } = await ArticlesResource.getAllGeneralArticles(query);

                // Verificar si hay datos
                if (data.data.length === 0) {
                    this.endOfData = true;
                    return;
                }

                // Mapear y agregar los artículos
                this.articles2.push(...data.data.map(article => this.formatArticle(article)));

                // Incrementar el número de página
                this.pageNumber += 1;
            } catch (error) {
                this.handleResponseErrors(error);
            } finally {
                this.loading = false;
            }
        },
        async saveRelations() {
            if (this.items.length === 0) {
                this.warning('No hay relaciones a guardar.');
                return;
            }

            const payload = this.items.map(item => {
                return {
                    id: 0,
                    rel_Clave_Id_Matriz: item.rel_Clave_Id_Matriz,
                    rel_Clave_Id_Concretos: item.rel_Clave_Id_Concretos,
                    rel_Clave_Id_Orlo: item.rel_Clave_Id_Orlo
                }
            })

            this.loading = true
            try {
                // Hacer la llamada a la API para guardar las relaciones
                const { data } = await ArticlesResource.saveCustomRelation(payload)
                if (data.isSuccesful) {
                    this.success('Relaciones guardadas exitosamente.');
                    this.items = [];
                    this.isActiveModal = false;
                    this.articles = []
                    this.articles2 = []  
                    this.currentPage = 1
                    this.visibleItems = []
                    this.selectedRowIndex = -1
                    await this.getArticles()
                    await this.getArticles2()
                } else {
                    this.danger(data.message)
                }

            } catch (error) {
                this.loading = false
                console.error("Error saving relations", error);
                this.danger('Error al guardar las relaciones.');
            } finally {
                this.loading = false
            }
        },
        unlinkedRelationShip() {
            console.log(this.relation)
            this.$swal({
                title: `Desvincular relación`,
                html: `¿Estás seguro de que deseas desvincular la relación del artículo <strong>${this.relation.nombre}</strong>?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        this.loading = true
                        const { data } = await ArticlesResource.unlinkedRelationShip(1, this.relation.rel_Clave_Id)
                        this.loading = false
                        if (data.isSuccesful) {
                            this.selectedRowIndex = -1
                            this.isLinked = false
                            this.success(data.message)
                            this.articles2 = []
                            this.articles = []
                            await this.getArticles()
                            await this.getArticles2()
                        } else {
                            this.danger(data.message)
                        }
                    }catch(e) {
                        this.loading = false
                        this.handleResponseErrors(e)
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            })
        },
        focusNextInput(index, nextInputRef) {
            const nextInput = this.$refs[`${nextInputRef}${index}`][0];
            if (nextInput) {
                nextInput.focus();
                nextInput.select();
            }
        },
        focusNextButton(index) {
            const button = this.$refs[`actionButton${index}`][0]

            if (button) {                
                button.focus();

                const item = this.articles[index];  
                this.addInCollection(item, index);
                
                let newIndex = index + 1;
                if (newIndex >= this.articles.length) {
                    newIndex = 0;
                }

                // Esperar un breve momento para asegurarnos de que el foco se establezca en el botón
                this.$nextTick(() => {
                    this.focusNextInput(newIndex, 'relacionMatrizInput');
                });
            }
        },       
        async addInCollection() {

            if (!this.claveArticulo || this.claveArticulo.trim() === '') {
                this.warning('Debe ingresar una clave de artículo origen válida.');
                return;
            }

            if (!this.claveArticulo2 || this.claveArticulo2.trim() === '') {
                this.warning('Debe ingresar una clave de artículo destino válida.');
                return;
            }

            const idEmpresa = this.relation.clave_Articulo ? this.filter2.idEmpresa : this.filter.idEmpresa
            const claveArticulo = idEmpresa == 1 ? this.claveArticulo : this.claveArticulo2

            // Buscar el artículo que ya existe en la colección
            let articuloDuplicado = this.items.find(item => 
                item.clave_Matriz === this.claveArticulo || 
                item.clave_Concretos === this.claveArticulo2
            );

            // Si se encuentra el duplicado, mostramos el mensaje correspondiente
            if (articuloDuplicado) {
                if (articuloDuplicado.clave_Matriz === this.claveArticulo) {
                    this.danger(`El artículo con la clave "${this.claveArticulo}" ya está en la lista y no se puede volver a agregar.`);
                } else if (articuloDuplicado.clave_Concretos === this.claveArticulo2) {
                    this.danger(`El artículo con la clave "${this.claveArticulo2}" ya está en la lista y no se puede volver a agregar.`);
                }
                return;
            }

            const idEmpresa1 = this.filter.idEmpresa == 1 ? 1 : this.filter2.idEmpresa
            const idEmpresa2 = this.filter2.idEmpresa == 2 ? 2 : this.filter.idEmpresa
            const priceList1 = this.filter.idEmpresa == 1 ? this.filter.precioEmpresaId : this.filter2.precioEmpresaId

            const key1 = this.filter.idEmpresa == 1 ? this.claveArticulo : this.claveArticulo2
            const key2 = this.filter2.idEmpresa == 2 ? this.claveArticulo2 : this.claveArticulo
            const priceList2 = this.filter2.idEmpresa == 2 ? this.filter2.precioEmpresaId : this.filter.precioEmpresaId

            try {
                this.loading = true
                const { data } = await ArticlesResource.getArticlesByKeysAsync(idEmpresa1, key1, priceList1, idEmpresa2, key2, priceList2) //findByPrimaryKey(idEmpresa, claveArticulo)
                this.loading = false
                if (data.isSuccesful) {

                    const articles = data.data
                    const firstItem = articles[0] //.find(item => item.clave_Articulo === key1)
                    const secondItem = articles[1] //.find(item => item.clave_Articulo === key2)

                    //Ahora agregamos en los items
                    this.items.push({
                        id: 0,
                        clave_Matriz: firstItem.clave_Articulo,
                        articulo_Matriz: firstItem.nombre,
                        rel_Clave_Id_Matriz: firstItem.articulo_Id,

                        clave_Concretos: secondItem.clave_Articulo,
                        articulo_Concretos: secondItem.nombre,
                        rel_Clave_Id_Concretos: secondItem.articulo_Id,
                        rel_Clave_Id_Orlo: null,
                    })

                    this.$nextTick(() => {
                        this.relation = {}
                        this.selectedRowIndex = -1
                        this.claveArticulo = ''
                        this.claveArticulo2 = ''
                    })    
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }finally {
                this.loading = false
            }
        },
        removeFromCollection(index) {
            this.items.splice(index, 1)
        },
        handleScroll() {
            const tableContainer = this.$refs.tableContainer;
            const bottomOfTable = tableContainer.scrollTop + tableContainer.clientHeight >= tableContainer.scrollHeight - 10;
            if (bottomOfTable && !this.loading) {
                this.currentPage++;
                this.loadItems();
            }
        },
        onSearch() {
            this.pageNumber = 1; // Reiniciar el número de página a 1 cuando se realiza una búsqueda
            // this.loadArticles(); // Cargar artículos basados en la búsqueda
        }
    },
    watch:{
        valueOption: {
            handler: function(value) {
                console.log(`State: ${value}`)
                this.loadItems()
            },
            deep: true
        },
        filter: {
            handler: function(value) {
                
                console.log('Filter 1', value)
                
                this.filter2.idEmpresa = null
                this.filter2.precioEmpresaId = null
                this.filter2.category_key = null
                this.filter2.warehouse_id = null
                this.filter2.attribute_id = null
                this.filter2.classificator_id = null
                this.filter2.valor_clasif_id = null
                this.filter2.idClasificador = null
                this.filter2.idClasificadorValor = null
                this.filter2.idPrecioLista = null
                this.articles = []
                this.articles2 = []
            },
            deep: true
        },
        noRelationated(value) {
            if (value) {
                this.currentPage = 1
                this.noRelationated2 = false; // Desactivar noRelationated2 si noRelationated está activo
            }
        },
        noRelationated2(value) {
            if (value) {
                this.currentPage = 1
                this.noRelationated = false; // Desactivar noRelationated si noRelationated2 está activo
            }
        },
        isActiveModal(value) {
            if (!value) {
                this.claveArticulo = ''
                this.claveArticulo2 = ''
                this.relation = {}
                this.selectedRowIndex = -1
            }
        },
        sortedArticulosCombinados() {
            this.loadItems();
        },
        relation(value) {
            if (value) {
                this.claveArticulo = value.clave_Articulo
                this.claveArticulo2 = value.clave_Articulo2
            }
        }
    }   
}
</script>

<!-- CSS personalizado -->
<style scoped>
    .td-clave-articulo {
        width: 120px; /* Ajusta el ancho de las columnas según sea necesario */
        white-space: wrap; /* Evita que el texto se envuelva en estas columnas */
    }

    .td-articulo {
        width: 200px; /* Ajusta el ancho de la columna Articulo según sea necesario */
    }

    /* Asegúrate de que el texto se envuelva en la columna Articulo */
    .text-wrap {
        word-wrap: break-word; /* Habilita el ajuste de texto en esta columna */
    }

    .table th, .table td {
        text-align: left; /* Alinea el texto a la izquierda */
    }

    .selected-row {
        background-color: #007bff; /* Color de fondo para la fila seleccionada */
        color: white; /* Color de texto para la fila seleccionada */
    }
</style>
<style>
.b-table-sticky-header {
    overflow-y: auto;
    min-height: 450px;
}

.table-wrapper {
  height: 600px; /* Ajusta la altura según tus necesidades */
  overflow-y: auto;
}

</style>